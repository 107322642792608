import React, { useState, useEffect } from 'react';
import TextInput from '../TextInput';

const TimeAdjustor = ({rowIndex, timespent, onChange}) => {


    const [time, setTime] = useState("00:00");
    useEffect(() => {
        setTime(timespent)
    }, [timespent])

    const incrementTime = () => {
        let [hours, minutes] = time.split(":").map(Number);
        minutes += 15;
        if (minutes >= 60) {
            hours = (hours + 1) % 24;
            minutes -= 60;
        }
        let timefformed = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
        setTime(timefformed);
        onChange(timefformed, (hours*60)+minutes)
    };

    const decrementTime = () => {
        let [hours, minutes] = time.split(":").map(Number);
        minutes -= 15;
        if (minutes < 0) {
            hours = (hours - 1 + 24) % 24;
            minutes += 60;
        }
         let timefformed =`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
        setTime(timefformed);
        onChange(timefformed, (hours*60)+minutes)
    };

    const onChangeManually = (val) => {
        if(val.indexOf(':') < 0){
            setTime(`${val.substring(0,2)}:${val.substring(2,4)}`)
        }else{
            let parts = val.split(':')
            let prefix=parts[0], postfix=parts[1]
            // if(parts[0].length === 0)
            //     prefix = "00"
            // else prefix = parts[0]
            // if(parts[1].length === 0)
            //     postfix = "00"
            // else postfix = parts[1]
             let timefformed =`${prefix}:${postfix}`
             setTime(timefformed);
             onChange(timefformed, (parseInt(prefix)*60)+parseInt(postfix))
        }
    }

    return (
        
        <div style={{border:'1px solid lightgrey',padding:'5px 14px',borderRadius:'5px',backgroundColor:'white',display:'flex'}}>
           { 
           (time) && 
            <>
               <button style={{width:'20px',height:'100%',fontWeight:'bold',background:'none'}} onClick={decrementTime}>-</button>
                <input
                    // type="time"
                    style={{width:'60%',border:'none',textAlign:'center',fontSize:'14px'}}
                    value={time}
                    maxLength={5}
                    onChange={(e) => onChangeManually(e.target.value)}
                    
                />
                 <button style={{width:'16px',height:'100%',fontWeight:'bold',background:'none'}} onClick={incrementTime}>+</button>
                
            </>
            }
        </div>
    );
};

export default TimeAdjustor;

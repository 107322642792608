import { useState, useEffect, useRef } from 'react';
import { Col, Grid, Row, Icon, Button, Table, IconButton, ButtonGroup, Whisper, Popover, Dropdown } from 'rsuite';
import Layout from "../../components/Layout";
import './styles.less';
import { Helmet } from "react-helmet-async";
import _, { set } from 'lodash';
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import SelectInputNoSearch from "../../components/SelectInputNoSearch"
import TablePagination from "rsuite/lib/Table/TablePagination";
import SelectDateRangePicker from "../../components/SelectDateRangePicker"
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { reducer as timeLogReducer, sliceKey as timeLogSliceKey, actions as timeLogActions } from './../../shared-redux/TimeLog/slice';
// import { reducer as artefactReducer, sliceKey as artefactSliceKey, actions as artefactActions } from './../../shared-redux/Artefacts/slice';
import { reducer as clientReducer, sliceKey as clientSliceKey, actions as clientActions } from '../../shared-redux/Clients/slice';
import { actions as legalRequestActions, reducer as legalRequestReducer, sliceKey as legalRequestSliceKey, } from '../../shared-redux/LegalRequest/slice';

// import { reducer as formReducer, sliceKey as formSliceKey, actions as formActions } from './../../shared-redux/FormBuilder/slice';
// import { formSaga } from "../../shared-redux/FormBuilder/saga";

// import { artefactsSaga } from "../../shared-redux/Artefacts/saga";
import { legalRequestSaga } from "../../shared-redux/LegalRequest/saga";
import { clientsSaga } from '../../shared-redux/Clients/saga';
import { selectClients } from "../../shared-redux/Clients/selectors";
import { selectLegalRequest } from "../../shared-redux/LegalRequest/selectors";
import { timeLogSaga } from './../../shared-redux/TimeLog/saga';
import Toast from "../../../services/toastService";
import XLSX from 'xlsx'
import { redirect } from '../../../utils/response';
import userService from "../../../services/userService";
import useUnsavedWarning from '../../components/useUnsavedChangedWarning';
import { selectTimeLog } from '../../shared-redux/TimeLog/selectors';
import TimeAdjustor from '../../components/TimeAdjustor';
import TextAreaInput from '../../components/TextAreaInput';

export const TimelogData = (props) => {

    useInjectReducer({ key: timeLogSliceKey, reducer: timeLogReducer });
    useInjectSaga({ key: timeLogSliceKey, saga: timeLogSaga });
    // useInjectReducer({ key: artefactSliceKey, reducer: artefactReducer });
    // useInjectSaga({ key: artefactSliceKey, saga: artefactsSaga });
    useInjectReducer({ key: clientSliceKey, reducer: clientReducer });
    useInjectSaga({ key: clientSliceKey, saga: clientsSaga });
    useInjectReducer({ key: legalRequestSliceKey, reducer: legalRequestReducer });
    useInjectSaga({ key: legalRequestSliceKey, saga: legalRequestSaga });
    // useInjectReducer({ key: formSliceKey, reducer: formReducer });
    // useInjectSaga({ key: formSliceKey, saga: formSaga });

    const tableRef = useRef(null);

    const dispatch = useDispatch();

    let currentDate = moment();
    const Ranges = [
        {
            label: 'This Week',
            value: [currentDate.clone().startOf('isoWeek'), currentDate.clone().endOf('isoWeek')]
        },
        {
            label: 'This Month',
            value: [moment().startOf('month'), moment().endOf('month')]
        },
        {
            label: 'This Quarter',
            value: [moment().startOf('quarter'), moment().endOf('quarter')]
        },
        {
            label: 'This Year',
            value: [moment().startOf('year'), moment().endOf('year')]
        },
        {
            label: 'Last Week',
            value: [moment().subtract(1, 'weeks').startOf('isoWeek'), moment().subtract(1, 'weeks').endOf('isoWeek')]
        },
        {
            label: 'Last Month',
            value: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        },
        {
            label: 'Last Quarter',
            value: [moment().subtract(1, 'quarter').startOf('quarter'), moment().subtract(1, 'quarter').endOf('quarter')]
        },
        {
            label: 'Last Year',
            value: [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
        },
    ];

    const { timeLogList } = useSelector(selectTimeLog)
    const { subusers } = useSelector(selectClients);
    const { counterparties } = useSelector(selectLegalRequest)
    const [property, setProperty] = useState({})
    const [supplierName, setSupplierName] = useState(null)
    const [userId, setUserId] = useState(null)
    const [fromDate, setFromDate] = useState(null)
    const [toDate, setToDate] = useState(null)
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [updatedRecords, setUpdatedRecords] = useState({})
    const [timeLogListTemp, setimeLogListTemp] = useState([])
    const [prompt, setUnsaved, setSaved] = useUnsavedWarning();

    useEffect(() => {
        setimeLogListTemp(timeLogList?.result ? timeLogList.result.map(x => ({
            ...x,
            "timeSpentString":`${String(Math.floor(x.timespent/60)).padStart(2, '0')}:${String(x.timespent%60).padStart(2, '0')}`,
        })) : [])
    },[timeLogList])

    // inserts changed records to array for saving
    // useEffect(() => {
        // const lraIds = Object.keys(updatedRecords);
        // if (lraIds.length) {
        //     setBulkSaves(() => {
        //         const temp = [];

        //         for (const lraId of lraIds) {
        //             if (updatedRecords[lraId]) {
        //                 const data = {
        //                     _id: lraId,
        //                     supportingFields: updatedRecords[lraId]
        //                 }
        //                 temp.push(data)
        //             }
        //         }
        //         return temp;
        //     });
        // }
    // }, [updatedRecords]);

    const options = ["As CSV", "As Excel"];
    // const [csvdata, setCSVData] = useState([])
    // const [exceldata, setExceldata] = useState([])
    const [user, setUser] = useState(null)
    const [isSubuser, setIsSubuser] = useState(null)

    useEffect(() => {
        // dispatch({
        //     type: artefactActions.fetchArtefacts.type,
        // });
        dispatch({
            type: legalRequestActions.fetchCounterParty.type,
        });
        // dispatch({
        //     type: signedActions.fetchAllUsedSupportingFields.type,
        // });
        // dispatch({
        //     type: signedActions.getMetaFieldSelection.type,
        // });
        // dispatch({
        //     type: formActions.fetchClientsForm.type,
        //     payload: {},
        // });

        userService.getUser().then(user => {
            setUser(user);
        });
        userService.isSubUser().then(issub => {
            setIsSubuser(issub);
        })
    }, []);


    const fetchLogs = () => {
        dispatch({
            type: timeLogActions.fetchTimeLogList.type,
            payload: {
                page,
                filterQueryParams: {
                    ...property,
                    limit: limit,
                },
                successCallback: (results) => {
                }
            }
        });

    }

    useEffect(() => {
        fetchLogs()
    }, [page, property, limit])

    const onExportClick = (type) => {
         if (window.confirm('This may take a few minutes, are you sure you cant to continue?')) {
             getAllLogs(type)
         }
    }

    const getAllLogs = (type, total1 = timeLogList.totalResult , pageNo1 = 1, totalCsvData1 = [], totalExcelData1 = []) => {
        let total = total1
        let pageNo = pageNo1
        let totalCsvData = [...totalCsvData1]
        let totalExcelData = [...totalExcelData1]

        dispatch({
            type: timeLogActions.fetchTimeLogList.type,
            payload: {
                type: "total",
                page: pageNo1,
                filterQueryParams: {
                    ...property,
                    limit: 1000 // total > 1000 ? 1000 : total,
                },
                successCallback: (finalResult) => {
                    total = total - 1000
                    pageNo = pageNo + 1;
                    // console.log("finalResult ==", finalResult)
                    // if(finalResult.result.){
                        const dataToDownloadExcel = getDataForDownload(finalResult, 'excel')
                        const dataToDownload = getDataForDownload(finalResult, 'csv')
                        totalCsvData = [...totalCsvData, ...dataToDownload];
                        totalExcelData = [...totalExcelData,...dataToDownloadExcel];
                        if(finalResult.result.length >= 1000) {
                            getAllLogs(type, total, pageNo, totalCsvData, totalExcelData)
                        }else{
                            // setCSVData(totalCsvData)
                            // setExceldata(totalExcelData)
                            if(type === 'excel'){
                                processresult(totalExcelData)
                            }else{
                                const csvContent = arrayToCsv(totalCsvData)
                                downloadBlob(csvContent, `Time_Log_${moment().format('YYYY-MM-DD-hh-mm-ss')}`, 'text/csv;charset=utf-8;')
                            }
                        }
                    // }
                    
                },
            }
        });
    }

    const downloadBlob = (content, filename, contentType) => {
        // Create a blob
        var blob = new Blob([content], { type: contentType });
        var url = URL.createObjectURL(blob);

        // Create a link to download it
        var pom = document.createElement('a');
        pom.href = url;
        pom.setAttribute('download', filename);
        pom.click();
    }

    const arrayToCsv = (data) => {
        let matter = []
        matter.push(Object.keys(data[0]).join(","))
        try{
            data.map(row => {
                matter.push(Object.values(row).join(","))
            })
            return matter.join('\r\n')
        }catch(error){
            console.log("err==", error)
        }
        // rows starting on new lines
    }

    useEffect(() => {
        dispatch({
            type: clientActions.getSubUsersWithParent.type,
        });
        // if (metaDataList.result) {
        //     let temp = metaDataList.result.map((item) => (
        //         item.legalrequestartefact
        //     ))
        //     setSupportingFields(temp)
        // }
    }, [])

    const handleChangePage = (dataKey) => {
        setPage(dataKey);
    }

    const handleChangeLimit = (dataKey) => {
        setLimit(dataKey);
    }

    useEffect(() => {
        let temp = { ...property }
        temp.supplierName = supplierName
        // temp.artefactId = artefactId
        // temp.matterOwnerId = matterOwnerId
        temp.userId = userId
        // temp.artefactStatus = artefactStatus
        temp.fromActivityDate = fromDate
        temp.toActivityDate = toDate
        // temp.matterCategoryId = matterCategory
        setProperty(temp)

    }, [supplierName, userId, fromDate, toDate])


    const getFinalValueForExport = (value, type) => {
        return (type === "csv") ? `"${value}"` : value
    }

    const getDataForDownload = (finalResult, type) => {
        let allData = []
        try {
            let rowData = finalResult.result ? finalResult.result : []
            rowData.map((item) => {
                let rawObject = {
                    'Counterparty': getFinalValueForExport(item.matter.supplierName, type),
                    'Matter Name': getFinalValueForExport(`${(item.matter.supplierShortName) ? item.matter.supplierShortName : item.matter.supplierName}${(item.matter.nameSuffix) ? ` | ${item.matter.nameSuffix}` : ""}`, type),
                    'Artefact': getFinalValueForExport(item.artefact.name, type),
                    'User': getFinalValueForExport(item.user.name, type),
                    'Date': (type === 'csv') ? moment(item.activityDate).format('YYYY-MM-DD') : new Date(moment(item.activityDate).format('YYYY-MM-DD')),
                    'Time Spent': getFinalValueForExport(`${String(Math.floor(item.timespent/60)).padStart(2, '0')}:${String(item.timespent%60).padStart(2, '0')}`, type),
                    'Workflow Task': item.step ? getFinalValueForExport(item.step.title, type) : '',
                    'Comment':getFinalValueForExport(item.comment, type)
                }
                allData.push(rawObject);
            })
        } catch (errpr) {
            console.log("errorrr=", errpr)
        }
        return allData
    }

    const processresult = (finalResult) => {
        const data = finalResult
        const workSheet = XLSX.utils.json_to_sheet(data, { cellDates: true, dateNF: 'YYYY-MM-DD' })
        const workBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workBook, workSheet, "contractData")

        // buffer
        let buffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' })

        XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' })
        XLSX.writeFile(workBook, `Time_Log_${moment().format('YYYY-MM-DD-hh-mm-ss')}.xlsx`)
    }

    const onNavigate = (rowData) => {
        if (isSubuser) {
            dispatch({
                type: legalRequestActions.fetchRequestUsers.type,
                payload: {
                    requestId: rowData.legalRequestArtefact.request,
                    successCallback: (users) => {
                        if (_.find(users, { userId: user.id })) {
                            redirect(`/workflow?request=${rowData.legalRequestArtefact.request}&counterparty=${rowData.matter.supplierName}&artefact=${rowData.legalRequestArtefact._id}`);
                        } else {
                            Toast.error("You do not have permission to view this matter")
                        }
                    }
                }
            });
        } else {
            redirect(`/workflow?request=${rowData.legalRequestArtefact.request}&counterparty=${rowData.matter.supplierName}&artefact=${rowData.legalRequestArtefact._id}`);
        }
    }

    const getData = () => {
        return  timeLogListTemp;
    };

    const onSave = (rowData) => {
        if(!updatedRecords[rowData._id]){
            Toast.error("No changes to save")
            return;
        }
        let finalTime = updatedRecords[rowData._id].timespent
        if(updatedRecords[rowData._id].timeSpentString){
            let [hours, minutes] = updatedRecords[rowData._id].timeSpentString.split(":").map(Number);
            if (hours === 0 && minutes < 15) {
                Toast.error("Please enter a value of at least 15 minutes for Time spent")
                return;
            }
            finalTime = (hours*60) + minutes
        }else if (!updatedRecords[rowData._id].timeSpentString && updatedRecords[rowData._id].timespent < 15) {
            Toast.error("Please enter a value of at least 15 minutes for Time spent")
            return;
        }

        const payloadData = {
           
            timespent: finalTime,//updatedRecords[rowData._id].timespent,
            comment: updatedRecords[rowData._id].comment
        }

        dispatch({
            type: legalRequestActions.editTimeLog.type,
            payload: {
              data: payloadData,
              timelogId: rowData._id,
              successCallback: () => {
                fetchLogs()
                setSaved()
            }

            }
        });
    }

    const onDataUpdate = (rowData, index, value, field) => {

        if(updatedRecords[rowData._id]){
            setUpdatedRecords({
                ...updatedRecords,
                [rowData._id]:{
                    ...updatedRecords[rowData._id],
                [field]: value
                }
            })
        }else{
            setUpdatedRecords({
                ...updatedRecords,
                [rowData._id]:{
                    ...rowData,
                [field]: value
                }
            })
        }
    }

    const { Column, Cell, HeaderCell } = Table;
    return (
        <Layout location={props.location} history={props.history} >
            <Helmet>
                <title>Time Log</title>
            </Helmet>

            <Grid fluid className="container contract-data-container">
                <Row className='contract-data-container-row'>
                    <Col md={4}>
                        <>
                            <SelectInputNoSearch
                                noLabel
                                stackedLabel
                                inputOptions={{
                                    menuStyle: { fontSize: '12px', width: '20px' },
                                    size: "xs",
                                    placeholder: 'Counterparty',
                                    cleanable: true,
                                    // creatable: true,
                                    data: counterparties,
                                    labelKey: "name",
                                    valueKey: "name",
                                    onChange: (value) => {
                                        setSupplierName(value)
                                        setPage(1)
                                    },
                                }}
                            />

                        </>
                    </Col>
                    <Col md={3}>
                        <>
                            <SelectInputNoSearch
                                noLabel
                                stackedLabel
                                inputOptions={{
                                    menuStyle: { fontSize: '12px' },
                                    size: "xs",
                                    placeholder: 'User',
                                    cleanable: true,
                                    data: subusers && subusers.length ? subusers : [],
                                    labelKey: "name",
                                    valueKey: "_id",
                                    onChange: (value) => {
                                        setUserId(value)
                                        setPage(1)
                                    },
                                }}
                            />

                        </>
                    </Col>
                    <Col md={5}>
                        <>
                            <SelectDateRangePicker
                                label="Select Date Range"
                                noLabel
                                styles={{ width: '100%', height: '40px' }}
                                // styles={{ height: "30px", width: '200px' }}
                                labelStyle={{ fontSize: 12 }}
                                inputOptions={{
                                    style: { height: '40px' },
                                    value: fromDate && toDate ? [fromDate, toDate] : [],
                                    size: "xs",
                                    placeholder: "Created Date",
                                    ranges: Ranges,
                                    onChange: (value) => {
                                        setFromDate(moment(value[0]).format('YYYY-MM-DD'))
                                        setToDate(moment(value[1]).format('YYYY-MM-DD'))
                                    },
                                    onOk: (value) => {
                                        setFromDate(moment(value[0]).format('YYYY-MM-DD'))
                                        setToDate(moment(value[1]).format('YYYY-MM-DD'))
                                    },
                                    onClean: () => {
                                        setFromDate(null)
                                        setToDate(null)
                                    }
                                }}
                            />

                        </>
                    </Col>

                    <Col md={4}>
                        <div className="d-flex flex-end">

                            {/* {csvdata.length > 0 && */}
                                <ButtonGroup style={{ width: '100%' }}>
                                    <Button onClick={() => onExportClick('excel')} style={{ width: '70%' }} className="color-primary text-color-white">{'Export'}</Button>
                                    <Whisper
                                        placement="bottomEnd"
                                        trigger="click"
                                        speaker={({ onClose, left, top, className }, ref) => {
                                            const handleSelect = eventKey => {
                                                onClose();
                                                // setAction(eventKey);
                                            };
                                            return (
                                                <Popover ref={ref} className={className} style={{ left, top }} full>
                                                    <Dropdown.Menu onSelect={handleSelect}>
                                                        {options.map((item, index) => (
                                                            <Dropdown.Item key={index} eventKey={index}>
                                                                {index == 1 &&
                                                                    <button className='export-csv-btn' onClick={() => onExportClick('excel')}>
                                                                        As Excel
                                                                    </button>}
                                                                {index == 0 &&
                                                                    <div onClick={() => onExportClick('csv')}>
                                                                    As CSV
                                                                        
                                                                    </div>
                                                                }
                                                            </Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Popover>
                                            );
                                        }}
                                    >
                                        <IconButton style={{ width: '20%' }} className="color-tertiary text-color-white" icon={<Icon icon="arrow-down" />} />
                                    </Whisper>

                                </ButtonGroup>

                            {/* } */}
                        </div>

                    </Col>
                    <Col md={3}>
                    </Col>
                    {
                        timeLogList && timeLogList.timeSpent && (
                        <Col md={5} style={{ fontSize: '14px' }} align="center">
                            {
                                (!timeLogList.timeSpent || !timeLogList.timeSpent.totalTimeSpent) ? 
                                <><strong>Total Time :</strong> 00:00</>
                                :
                                 <><strong>Total Time :</strong> {String(Math.floor(timeLogList?.timeSpent?.totalTimeSpent/60)).padStart(2, '0')} : {String(timeLogList?.timeSpent?.totalTimeSpent%60).padStart(2, '0')}</>
                            }
                           
                        </Col>
                        )
                    }
                    
                </Row>
                <hr
                    style={{
                        color: '#000000',
                        height: .5,

                    }}
                />

                {/* <Row style={{ padding: 0, position: "relative", bottom: "13px" }}>
                    <Col md={3} mdOffset={21}>
                        <div className="d-flex flex-end">
                            <ButtonGroup>
                                <Button onClick={onSaveAll} style={{ width: '100%', padding: "" }} className="color-primary text-color-white">
                                    Save All
                                </Button>
                            </ButtonGroup>
                        </div>
                    </Col>
                </Row> */}

                <Col md={24} className='contract-data-table' >
                    <Table
                        ref={tableRef}
                        affixHorizontalScrollbar
                        autoHeight
                        className=""
                        data={getData()}
                        rowHeight={50}
                        virtualized

                    >

                     <Column verticalAlign="middle" align="center" width={150} resizable >
                            <HeaderCell  >Counterparty</HeaderCell>
                            <Cell dataKey="supplierName" style={{ width: "300px", fontSize: "12px" }}>
                                {rowData => rowData.matter.supplierName ? rowData.matter.supplierName : ''}

                            </Cell>
                        </Column>

                         <Column verticalAlign="middle" align="center" width={150} resizable >
                            <HeaderCell >Matter Name</HeaderCell>

                            <Cell dataKey="matterName" style={{ fontSize: '12px' }}>
                                {rowData => `${(rowData.matter.supplierShortName) ? rowData.matter.supplierShortName : rowData.matter.supplierName}${(rowData.matter.nameSuffix) ? ` | ${rowData.matter.nameSuffix}` : ""}`}
                            </Cell>
                        </Column>

                         <Column verticalAlign="middle" align="center" width={150} resizable >
                            <HeaderCell >Artefact</HeaderCell>

                            <Cell dataKey="artefactId" style={{ fontSize: '12px' }}>
                                {rowData => rowData.artefact ? rowData.artefact.name : ''}
                            </Cell>
                        </Column>

                         <Column verticalAlign="middle" align="center" width={150} resizable>
                            <HeaderCell >User</HeaderCell>

                            <Cell dataKey="user" style={{ fontSize: '12px' }}>
                                {rowData => rowData.user ? rowData.user.name : ''}
                            </Cell>
                        </Column>
                         <Column verticalAlign="middle" align="center" width={150} resizable>
                            <HeaderCell >Date</HeaderCell>

                            <Cell dataKey="activityDate" style={{ fontSize: '12px' }}>
                                {rowData => rowData.activityDate ? moment(rowData.activityDate).format('YYYY-MM-DD') : ''}
                            </Cell>
                        </Column>
                         <Column verticalAlign="middle" align="center" width={150} resizable>
                            <HeaderCell >Time Spent</HeaderCell>

                            <Cell dataKey="timespent" style={{ fontSize: '12px' }}>
                                {(rowData, index) => {
                                    // let time = updatedRecords[rowData._id] && updatedRecords[rowData._id].timeSpentString ? updatedRecords[rowData._id].timeSpentString : rowData.timeSpentString
                                    return  <TimeAdjustor rowIndex={index} 
                                                // timespent={`${String(Math.floor(time/60)).padStart(2, '0')}:${String(time%60).padStart(2, '0')}`} 
                                                timespent={updatedRecords[rowData._id] && updatedRecords[rowData._id].timeSpentString ? updatedRecords[rowData._id].timeSpentString : rowData.timeSpentString}
                                                onChange={(timeString, timeInMins) => {
                                        // let [hours, minutes] = timeString.split(":").map(Number);
                                        // onDataUpdate(rowData, index, (hours*60) + minutes, "timespent")
                                        onDataUpdate(rowData, index, timeString, "timeSpentString")
                                    }}/>
                                }}
                            </Cell>
                        </Column>
                         <Column verticalAlign="middle" align="center" width={150} resizable>
                            <HeaderCell>Workflow Task</HeaderCell>

                            <Cell dataKey="status" style={{ fontSize: '12px' }}>
                            {rowData => rowData.step ? (rowData.step.title ? rowData.step.title : rowData.step.name) : ''}
                            </Cell>
                        </Column>
                        <Column verticalAlign="middle" align="center" width={150} resizable>
                            <HeaderCell >Comment</HeaderCell>

                            <Cell dataKey="comment" style={{ fontSize: '12px' }}>
                                {(rowData, index) => {
                                    return   <TextAreaInput
                                    noLabel
                                    inputOptions={{
                                        // disabled: (isDisable),
                                        style: { width: '100%', height: '40px', fontSize: '12px', resize: 'none', padding: '5px' },
                                        value: updatedRecords[rowData._id] ? updatedRecords[rowData._id].comment : rowData.comment,
                                        // placeholder: (item.placeholder) ? item.placeholder : "Description",
                                        rows: 3,
                                        onChange: (e) => {
                                            
                                            onDataUpdate(rowData, index, e.target.value, "comment")
                                            setUnsaved()
                
                                        },
                                    }}
                                />
                                }}
                            </Cell>
                        </Column>

                        <Column verticalAlign="middle" align="center" fixed="right" width={50}>
                            <HeaderCell>
                                {/* <a onClick={() => {}} className='save-all'> Save All</a> */}
                            </HeaderCell>
                            <Cell dataKey="save" style={{ fontSize: '12px' }}>
                                {
                                    rowData => {
                                            return (
                                            <span>
                                                <IconButton
                                                    icon={<Icon icon="save" />}
                                                    circle
                                                    size="sm"
                                                    className="text-color-white color-primary"
                                                    onClick={() => onSave(rowData)}
                                                />
                                            </span>
                                        )   
                                    }
                                }
                            </Cell>
                        </Column>

                        <Column verticalAlign="middle" align="center" fixed="right" width={50}>
                            <HeaderCell style={{ fontSize: "12px" }}></HeaderCell>

                            <Cell dataKey="goto" style={{ fontSize: '12px' }}>
                                {
                                    rowData => {
                                        return (
                                            <span>
                                                {<IconButton
                                                    icon={<Icon icon="arrow-right-line" />}
                                                    circle
                                                    size="sm"
                                                    className="text-color-white color-primary"
                                                    // disabled={(rowData.legalrequestartefact.supportingFields && rowData.legalrequestartefact.supportingFields.length > 0 ? false : true) || rowData.legalrequestartefact.isCompleted}
                                                    onClick={() => onNavigate(rowData)}
                                                />}
                                            </span>
                                        );
                                    }
                                }
                            </Cell>
                        </Column>
                    </Table>
                    <TablePagination
                        lengthMenu={[
                            {
                                value: 10,
                                label: 10
                            },
                            {
                                value: 20,
                                label: 20
                            },
                            {
                                value: 50,
                                label: 50
                            },
                            {
                                value: 100,
                                label: 100
                            }
                        ]}
                        activePage={page}
                        displayLength={limit}
                        total={timeLogList.totalResult}
                        onChangePage={handleChangePage}
                        onChangeLength={handleChangeLimit}
                    />
                </Col>


            </Grid>
            {prompt}
        </Layout>
    );
}